<template>
  <div class="add-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/live/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加直播</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="left" label-width="110px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="addForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="直播标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="直播缩略图" prop="thumbnail">
          <upload :url="addForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="直播外链地址" prop="link">
          <el-input v-model="addForm.link"></el-input>
          <div class="tips">仅支持以下视频格式 mp4  mov  m4v  3gp  avi m3u8 webm, 编码格式支持：H.264  HEVC  MPEG-4  VP9</div>
        </el-form-item>
        <el-form-item label="介绍" prop="desc">
          <QuillBar v-model="addForm.desc" :contenttext="addForm.desc" @inputChange="descChange"></QuillBar>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="addForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('addRef')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      addForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        thumbnail: '',
        link: '',
        desc: '',
        is_auth: 2
      },
      addRules: {
        title: [
          { required: true, message: '请填写直播标题', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
        ],
        link: [
          { required: true, message: '请填写直播外链地址', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    // 添加表单
    submitForm (formname) {
      this.$refs.addRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-live/0', this.addForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.addRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/live/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 直播介绍
    descChange (value) {
      this.addForm.desc = value
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/live/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    }
  }
}
</script>

<style scoped>
.add-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.tips{
  font-size: 12px;
  color: #F56C6C;
}
</style>
